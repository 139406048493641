import { mapState, mapActions } from 'vuex'
import Vue from 'vue'
import { Empty, Toast, Dialog, Loading, Popup, Collapse, CollapseItem } from 'vant'
import { setTimeout } from 'core-js'

Vue.use(Collapse)
Vue.use(CollapseItem)
Vue.use(Popup)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Empty)
Vue.use(Toast)
export default {
  components: {},
  data () {
    return {
      id: '',
      name: '',
      begin: '',
      end: '',
      begin1: '',
      end1: '',
      userEquityAvailableCount: '',
      userEquityAvailableAllCount: '',
      cishu: true,
      isStaleDated:0
    }
  },
  created () {
    this.getList()
  },

  methods: {
    ...mapActions('equityCenter', ['usageEquity', 'getEquityDetail','onlineInquiry']),
    back () {
      var isback=this.$route.query.isback
      this.$router.push({ name: 'equityCenter'})
      // if(isback==1){
      //   this.$router.push({ name: 'equityCenter'})
      // }else{
      //   this.$router.go(-1)

      // }

    },
    getList () {
      this.id = this.$route.query.id
      var data = {
        id: this.id

      }
      this.getEquityDetail(data).then((res) => {
        if (res.code == 200) {
          this.name = res.data.userEquityRightName
          this.begin = res.data.userEquityStartDate
          this.end = res.data.userEquityEndDate
          this.begin1 = res.data.userEquityStartDate.split('-').join('.')
          this.end1 = res.data.userEquityEndDate.split('-').join('.')
          this.isStaleDated=res.data.isStaleDated
          if (res.data.userEquityAvailableCount == '-1') {
            this.userEquityAvailableCount = '无限次'
          } else {
            this.userEquityAvailableCount = res.data.userEquityAvailableCount
          }
          if (res.data.userEquityAvailableCount == '-1') {
            this.userEquityAvailableAllCount = '无限次'
          } else if (res.data.userEquityAvailableCount == '0') {
            this.cishu = false
            this.userEquityAvailableAllCount = res.data.userEquityAvailableAllCount

          } else {
            this.userEquityAvailableAllCount = res.data.userEquityAvailableAllCount
          }
        } else {

        }
      })
    },
    toNum () {
      // console.log(this.name)
      if (this.name == '电话健康咨询') {
        this.$router.push({ name: 'estimate', query: { name: this.name, id: this.id,title:this.name,begin:this.begin,end:this.end } })
      } else if (this.name == '协助办理出院') {
        this.$router.push({ name: 'accompany', query: { name: this.name,id: this.id ,title:this.name,begin:this.begin,end:this.end } })
      } else if (this.name == '在线健康导诊') {
        this.onlineInquiry({
          id:this.id
        }).then(res=>{
          if(res.code==200){
            window.location.href=res.url

          }

        })

      } else if (this.name == '康复指导') {
        this.$router.push({ name: 'doorService', query: { id: this.id, name: this.name,title:this.name,begin:this.begin,end:this.end   } })
      } else if (this.name == '复诊预约挂号') {
        this.$router.push({ name: 'followUpAppointment', query: { id: this.id,title:this.name,begin:this.begin,end:this.end } })

      }
      // if (this.id == '11') {
      // 协助办理出院
      // this.$router.push({ name: 'accompany',query: { name: this.name } })
      // } else if (this.id == '21') {
      // 在线健康导诊
      // } else if (this.id == '27') {
      // 电话健康咨询
      //   this.$router.push({ name: 'estimate' ,query: { name: this.name }})
      // } else if (this.id == '37') {
      // 康复指导
      //   this.$router.push({ name: 'doorService',query: { name: this.name } })
      // } else if (this.id == '47') {
      // 复诊预约挂号
      // }
    },
    toUseList () {
      this.$router.push({ name: 'useList', query: { id: this.id } })
    },

  },
}
